// utils/route-attributes.ts
type RouteNames = "home" | (string & {});

interface DocumentWithDataAttributes extends Document {
  body: HTMLBodyElement & {
    setAttribute(name: "data-route" | "data-is-loaded", value: string): void;
  };
}

export const getRouteFromPathname = (path: string): RouteNames => {
  const segments = path.slice(1).split("/");
  return segments[0] === "" ? "home" : segments[0] || "home";
};

export const setDocumentAttributes = (routeName: RouteNames): void => {
  const document = window.document as DocumentWithDataAttributes;
  document.body.setAttribute("data-route", routeName);
};

export const handleDocumentLoad = (): void => {
  const document = window.document as DocumentWithDataAttributes;
  document.body.setAttribute("data-is-loaded", "true");
};
