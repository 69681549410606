"use client";

import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";

import NavigationDrawer from "@/components/navigation/navigation-drawer";

const TopAppBar: React.FC = (): React.JSX.Element => {
  const pathname = usePathname();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Automatically open the menu if the pathname matches a menu route
  useEffect(() => {
    setIsMenuOpen(pathname === "/menu" || pathname === "/menu/");
  }, [pathname]);

  const toggleMenu = (): void => setIsMenuOpen((prev) => !prev);
  const closeMenu = (): void => setIsMenuOpen(false);

  return (
    <div className="top-app-bar" role="banner">
      <button
        aria-controls="main-navigation"
        aria-expanded={isMenuOpen}
        aria-haspopup="true"
        aria-label={isMenuOpen ? "Close Menu" : "Open Menu"}
        className="site-menu-button"
        data-menu-is-open={isMenuOpen}
        onClick={toggleMenu}
      >
        <em aria-hidden="true" />
        <em aria-hidden="true" />
        <em aria-hidden="true" />
      </button>
      <NavigationDrawer isOpen={isMenuOpen} onClose={closeMenu} />
    </div>
  );
};

export default TopAppBar;
