import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/app-bars/top-app-bar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RouteAttributeHandler"] */ "/vercel/path0/components/route-attribute-handler.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/_default.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/_app.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/application-shell.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/images.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/links-and-buttons.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/lists.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/navigation-drawer.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/top-app-bar.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/forms.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"fallback\":[\"Helvetica\",\"ui-sans-serif\"],\"src\":[{\"path\":\"../public/fonts/libre-baskerville/bold.woff2\"},{\"path\":\"../public/fonts/libre-baskerville/italic.woff2\"},{\"path\":\"../public/fonts/libre-baskerville/regular.woff2\"}]}],\"variableName\":\"customFont\"}");
