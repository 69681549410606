import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useEffect, useRef } from "react";

import { getPageName, isInCurrentDirectory } from "@/utils/helpers";

interface NavigationDrawerProps {
  isOpen: boolean;
  onClose?: () => void;
}

interface NavigationItem {
  href: string;
  label: string;
  ariaLabel?: string;
}

const navigationItems: NavigationItem[] = [
  { ariaLabel: "About the artist", href: "/about", label: "About" },
  {
    ariaLabel: "View paintings gallery",
    href: "/gallery/paintings",
    label: "Paintings",
  },
  {
    ariaLabel: "View sculpture gallery",
    href: "/gallery/sculptures",
    label: "Sculptures",
  },
  {
    ariaLabel: "View earlier works gallery",
    href: "/gallery/earlier-works",
    label: "Earlier works",
  },
  { ariaLabel: "Contact information", href: "/contact", label: "Contact" },
  { ariaLabel: "Return to home page", href: "/", label: "Home" },
];

const NavigationDrawer: React.FC<NavigationDrawerProps> = ({ isOpen, onClose }) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const firstFocusableRef = useRef<HTMLAnchorElement>(null);
  const lastFocusableRef = useRef<HTMLAnchorElement>(null);

  const pathname: string = usePathname() || "";

  useEffect(() => {
    if (!isOpen) return;

    const handleKeyDown = (event: KeyboardEvent): void => {
      const { key, shiftKey } = event;

      if (key === "Escape") {
        event.preventDefault();
        onClose?.();
      } else if (key === "Tab") {
        const { activeElement } = document;
        if (activeElement === lastFocusableRef.current && !shiftKey) {
          event.preventDefault();
          firstFocusableRef.current?.focus();
        } else if (activeElement === firstFocusableRef.current && shiftKey) {
          event.preventDefault();
          lastFocusableRef.current?.focus();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return (): void => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose]);

  return (
    <div
      aria-label="Main navigation"
      className="navigation-drawer"
      data-navigation-drawer-is-open={isOpen}
      ref={drawerRef}
      role="navigation"
    >
      <nav>
        <ul aria-orientation="vertical" className="stacked-navigation" role="menu">
          {navigationItems.map(({ href, label, ariaLabel }, index) => {
            const isInDirectory = isInCurrentDirectory(pathname, href); // Evaluate for each item
            const isCurrentPage = getPageName(pathname) === getPageName(href);

            return (
              <li key={href}>
                <Link
                  aria-current={isCurrentPage ? "page" : undefined}
                  aria-label={ariaLabel || label}
                  data-is-in-current-directory={isInDirectory ? "true" : "false"}
                  href={href}
                  ref={
                    index === 0
                      ? firstFocusableRef
                      : index === navigationItems.length - 1
                        ? lastFocusableRef
                        : undefined
                  }
                >
                  {label}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default NavigationDrawer;
