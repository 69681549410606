"use client";

import { usePathname } from "next/navigation";
import { type FC, useEffect } from "react";

import {
  getRouteFromPathname,
  handleDocumentLoad,
  setDocumentAttributes,
} from "@/utils/route-attributes";

export const RouteAttributeHandler: FC = () => {
  const pathname = usePathname();

  useEffect(() => {
    const routeName = getRouteFromPathname(pathname);
    setDocumentAttributes(routeName);

    if (document.readyState === "complete") {
      handleDocumentLoad();
    } else {
      window.addEventListener("load", handleDocumentLoad);
    }

    return (): void => {
      window.removeEventListener("load", handleDocumentLoad);
    };
  }, [pathname]);

  return null;
};
